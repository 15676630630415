$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Accordion
 */

.accordion {
	&__item {
		position: relative;
		margin-block-end: $accordion-item-offset;
		background-color: $accordion-item-bg;
		border-radius: $accordion-item-border-radius;
		transition: background-color $transition-speed $transition-easing;

		&--active {
			box-shadow: $accordion-item-box-shadow;

			.accordion__item-body {
				max-height: 9999px;
				padding: logical 0 $accordion-body-padding-mobile-x $accordion-body-padding-mobile-y;

				// Media queries
				@include media-breakpoint-up(sm) {
					padding: logical 0 $accordion-item-padding-x ( $accordion-item-padding-y * 1.5 );
				}
			}
		}

		&:not(.accordion__item--active) {
			&:hover,
			&:focus {
				background-color: $accordion-item-hover-bg;
			}
		}
	}

	&__item-header {
		padding: logical $accordion-header-padding-mobile-y $accordion-header-padding-mobile-x;
		cursor: pointer;

		// Media queries
		@include media-breakpoint-up(sm) {
			padding: logical $accordion-header-padding-y $accordion-header-padding-x;
		}
	}

	&__item-title {
		margin-block-end: 0;
	}

	&__item-body {
		max-height: 0;
		overflow: hidden;
		padding: logical 0 $accordion-body-padding-mobile-x 0;

		// Media queries
		@include media-breakpoint-up(sm) {
			padding: logical 0 $accordion-item-padding-x 0;
		}
	}

	&__item-row {
		padding: logical $accordion-item-padding-mobile-y $accordion-item-padding-mobile-x;
		border-block-end: $accordion-item-border-width solid $acordion-item-border-color;

		// Media queries
		@include media-breakpoint-up(sm) {
			padding: logical $accordion-item-padding-y $accordion-item-padding-x;
		}

		&--primary {
			background-color: $accordion-item-primary-bg;
			font-weight: $accordion-item-primary-weight;
			border-block-end: none;
			border-radius: $accordion-item-border-radius;
		}

		&--all {
			.form-group--checkbox {
				min-width: 0;
				padding-inline-start: 0;
				height: 100%;

				.control-label {
					height: 100%;
					text-transform: none;
					text-align: end;
					padding-block-end: ( $spacer * .25 + $custom-control-indicator-size );

					&::before {
						background-color: $input-focused-bg;
					}

					&::after,
					&::before {
						inset-inline: auto 0;
						inset-block: auto 0;
					}
				}
			}
		}

		&:last-child {
			border-block-end: none;

			// Media queries
			@include media-breakpoint-up(md) {
				padding-block-end: 0;
			}
		}
	}

	.form-group--checkbox {
		min-width: 0;

		.control-label {
			text-transform: none;

			&::after,
			&::before {
				inset-inline: auto 0;
			}
		}
	}
}

	.accordion__item-body--formatted {
		white-space: pre-line;
	}