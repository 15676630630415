$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Form help text / invalid feedback
 -  (<span class="invalid-feedback" />)
 -  (<span class="help-text" />)
 */

.valid-feedback,
.invalid-feedback,
.form-text {
	padding-inline-start: calc(#{$input-padding-x} + #{$input-border-width});
	margin-block-start: .4rem;
	margin-block-end: 0;
	font-weight: 600;
}

.form-text {
	font-size: 1.2rem;
	color: $text-muted;
}

.form-text.invalid-feedback {
	color: $danger;
}