$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Menu Item
 */


.menu__item {
	width: 100%;
	padding: logical ( $spacer * .5 ) $spacer;
	// IE11 fix
	// https://cisvsts.visualstudio.com/CBSUI/_workitems/edit/76464
	overflow: hidden;

	&--no-gutters {
		padding: 0;
	}

	&:hover:not([class*='disabled']),
	&:focus:not([class*='disabled']),
	&.active:not([class='disabled']) {
		background-color: $theme-gray-100;
		cursor: pointer;
	}

	.form-control[type='checkbox'] + .control-label {
		text-transform: none;
		letter-spacing: normal;
		width: 100%;
	}
}
