/*
 -  Master styles
 -
 -  This file defines html elements styles.
 */


ul {
	margin-block-end: $unordered-list-margin-bottom;
}

ol {
	margin-block-end: $ordered-list-margin-bottom;
}

img {
	margin-block-end: $image-margin-bottom;
}

hr {
	background-color: $theme-gray-350;
	box-shadow: none;
	border: none;
	height: 1px;
}

dl {
	dd {
		padding-inline-end: ( $spacer * .5 );
	}

	dt {
		min-width: 20%;
		flex-shrink: 0;
	}
}

.table {
	width: 100%;

	&__cell {
		display: block;

		// Media queries
		@include media-breakpoint-up(sm) {
			display: table-cell;

			& + & {
				padding-inline-start: $spacer;
			}
		}
	}

	&__row {
		display: block;
		padding: ( $spacer * .5 ) 0;
		border-bottom: 1px solid $theme-gray-300;

		// Media queries
		@include media-breakpoint-up(sm) {
			padding: 0;
			display: table-row;
			border-bottom: none;
		}
	}

	&--calendar {
		min-width: 800px;

		th {
			color: $info;
			font-weight: 400;
		}

		tr {
			border-bottom: 1px solid $theme-gray-300;
		}

		thead {
			th {
				padding-block-end: ( $spacer * .75 );
				text-align: center;

				&:first-child {
					text-align: start;
					width: 90px;
				}
			}
		}

		tbody {
			th,
			td {
				padding-block: ( $spacer * .5 );
			}

			td {
				text-align: center;
			}
		}
	}
}

.link {
	&,
	&:visited {
		text-decoration: underline;
		color: $body-color;
	}

	&:hover,
	&:focus {
		color: $primary;
	}
}

.data-status {
	min-height: 100px;

	// Media queries
	@include media-breakpoint-up(sm) {
		min-height: 260px;
	}

	[class^='icon'],
	[class*='icon'] {
		// Overwrite default utility classes
		// stylelint-disable-next-line
		color: $theme-gray-600 !important;
	}

	p {
		font-size: 1.8rem;
		color: $theme-gray-600;
	}
}

.header-lp {
	padding-block-start: 90px;
}

.section {
	padding: logical $spacer 0;

	// Media queries
	@include media-breakpoint-up(md) {
		padding: logical ( $spacer * 2 ) 0;
	}
	@include media-breakpoint-up(lg) {
		padding: logical ( $spacer * 5 ) 0;
	}

	&__heading,
	&__text {
		margin-block-end: ( $spacer * .75 );

		// Media queries
		@include media-breakpoint-up(md) {
			margin-block-end: ( $spacer * 1.25 );
		}
		@include media-breakpoint-up(lg) {
			margin-block-end: ( $spacer * 1.75 );
		}
	}

	&__heading.h1 {
		margin-bottom: $spacer;
	}

	&__text {
		line-height: 22px;
	}

	&--bordered {
		border-bottom: 1px solid $theme-gray-350;
	}

	&--header {
		background-color: $theme-white;
		overflow: hidden;

		// Media queries
		@include media-breakpoint-down(sm) {
			padding-block-start: 0;
		}
		@include media-breakpoint-up(lg) {
			padding-block-end: ( $spacer * 2 );
		}
		@include media-breakpoint-up(xl) {
			min-height: 70vh;
		}

		.intro-video {
			display: block;
			width: 100%;

			// Media queries
			@include media-breakpoint-up(md) {
				width: 130%;
				margin-block-start: -( $spacer * 2 );
			}
			@include media-breakpoint-up(lg) {
				margin-block-start: -( $spacer * 4 );
			}
			@include media-breakpoint-up(xl) {
				margin-block-start: -( $spacer * 6 );
				width: 150%;
			}
		}
	}

	&--credit-score {
		overflow: hidden;

		.credit-score-anim {
			// Media queries
			@include media-breakpoint-up(lg) {
				margin-top: -130px;
			}
		}
	}

	&--benefits {
		// Media queries
		@include media-breakpoint-up(lg) {
			padding-block-end: 40px;
		}
	}

	&--footer {
		background-color: $theme-gray-100;

		@include media-breakpoint-up(lg) {
			padding-bottom: $spacer * 2;
		}
	}

	&--benefits,
	&--pricing,
	&--registration {
		background-color: $theme-gray-50;
	}

	&--myci-app {
		position: relative;
		padding-block-end: ( $spacer * 2 );
		background: linear-gradient(to bottom, #2a303c, #1d222e);
		overflow: hidden;

		.app-img {
			margin: 0 auto;
			max-width: 60%;

			@include media-breakpoint-up(md) {
				max-width: 80%;
			}
		}
	}

	&--registration {
		.reg-img {
			margin-block-start: -100px;
			// Media queries
			@include media-breakpoint-down(sm) {
				width: 60%;
				display: block;
				margin-inline-end: auto;
				margin-inline-start: auto;
			}
		}
	}
}

.app-version {
	padding-top: $spacer;
	text-align: center;

	@include media-breakpoint-up(md) {
		padding-top: $spacer * 2;
	}
}

.privacy-policy {
	padding-top: ($spacer * .5);
	text-align: center;
}

.checklist {
	margin: 0;
	padding: 0;
	list-style-type: none;
	max-width: 90%;

	&__item {
		display: flex;
		align-items: baseline;
		margin-block-end: $spacer;

		&::before {
			@include icon('success');
			font-size: 1.5rem;
			padding-inline-end: ( $spacer * .5 );
		}
	}

	&--teal {
		.checklist__item::before {
			color: $teal;
		}
	}
}

.pricing-plan {
	position: relative;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: 0 10px 50px 0 rgba($theme-black, .1);
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: transform $transition-speed $transition-easing;
	overflow: hidden;

	&:hover {
		transform: translateY(-5px);
	}

	&.selected {
		transform: translateY(-20px);
	}

	h3 {
		padding-inline-end: $spacer * 2;
		padding-inline-start: $spacer * 2;
	}

	&__price,
	&__title {
		font-weight: 800;
		text-align: center;
	}

	&__price {
		font-size: 3.6rem;

		// Media queries
		@include media-breakpoint-up(md) {
			font-size: 4.8rem;
		}
	}

	&__title {
		text-align: center;
		line-height: 1.2;
		font-size: 1.8rem;

		// Media queries
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
	}

	&__img {
		position: absolute;
		top: -45px;
		right: -50px;
	}
}

.layout {
	display: flex;
	flex-direction: column;
	height: 100vh;

	&__main {
		flex: 1 0 auto;
	}
}