$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Panel Title
 */


.panel-title {
	padding-inline: $panel-padding-x-mobile;

	// Media queries
	@include media-breakpoint-up(xl) {
		padding-inline: $panel-padding-x;
	}

	&__heading {
		font-size: 1.6rem;
	}
}