/*
 -	Master Page support
 */


.mp-section {
	padding: ( $grid-gutter-width / 4 ) 0 $grid-gutter-width;
	margin-bottom: $grid-gutter-width * 2;
	border-bottom: 1px solid darken($theme-white, 8%);

	&__title {
		margin-bottom: $grid-gutter-width;

		&::first-letter {
			text-transform: uppercase;
		}
	}
}

[class^='mp-support-color-'],
[class*='mp-support-color-'] {
	margin-bottom: ( $grid-gutter-width / 3 );
	border: 10px solid darken($theme-white, 5%);
	padding-top: ( $grid-gutter-width * 2 );
	border-radius: $border-radius;
}

$mp-colors: map-merge(
	$theme-colors,
	(
	  'red':    $theme-red,
	  'teal':   $theme-teal
	),
);

@each $name, $color in $mp-colors {
	.mp-support-color-#{$name} {
		background-color: #{$color};
	}
}

.mp-support-color__value,
.mp-support-icon__value {
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	background-color: $theme-white;
	padding: ( $grid-gutter-width / 4 ) ( $grid-gutter-width / 3 ) ( $grid-gutter-width / 6 );
	@include legacy-font-size(13);
}

.mp-support-icon {
	text-align: center;
	margin-bottom: ( $grid-gutter-width / 3 );
	border: 5px solid $theme-white;

	i {
		display: block;
		padding: ( $grid-gutter-width / 2 ) 0;
		@include legacy-font-size(24);
	}

	&:hover,
	&:focus {
		background-color: $theme-white;
		color: $primary;
	}
}
