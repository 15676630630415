@import '../../scss/core';

/*
 -  Modal
 -  (<div class="modal" />)
 */


.modal {
	position: relative;
	width: 100%;
	min-height: 100px;
	padding: logical ( $spacer * 2 ) $spacer ( $spacer * 3 );
	border-radius: $border-radius;
	background-color: $modal-bg;
	box-shadow: $modal-box-shadow;
	transform: translateY(-40px);
	transition: transform ( $transition-speed * 2 ) $transition-easing;

	// Media queries
	@include media-breakpoint-up(sm) {
		max-width: $modal-width;
		padding: logical ( $spacer * 2 ) $spacer ( $spacer * 3 );
	}

	&__close {
		position: absolute;
		inset-block-start: $spacer;
		inset-inline-end: $spacer;
		min-width: auto;
		width: 30px;
		height: 30px;
		padding: logical 0;

		.icon--type-cancel {
			padding-inline-end: 0;
		}
	}

	&__title {
		font-weight: $modal-title-font-weight;
		font-size: $modal-title-font-size;
		margin-block-end: $spacer;
	}
}