$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Timeline holder
 */


.timeline {
	position: relative;
	@include clearfix;

	// Media queries
	@include media-breakpoint-down(md) {
		padding-inline-start: ( $timeline-message-point-size-first * .5 );
	}

	&::before {
		content: '';
		position: absolute;
		width: 1px;
		transform: translateX(-50%);
		inset-block: $spacer 0;
		background-color: $theme-gray-400;

		@at-root [dir='rtl'] & {
			transform: translateX(50%);
		}
	}

	&--double {
		&::before {
			// Media queries
			@include media-breakpoint-up(lg) {
				inset-inline-start: 50%;
			}
		}

		.timeline-message {
			// Media queries
			@include media-breakpoint-up(lg) {
				width: 50%;

				&:nth-child(2) {
					margin-block-start: ( $spacer * 2 );
				}
			}
		}
	}
	&--closed {
		&::before {
			inset-block-end: $timeline-message-point-position;
		}

		.timeline-message:last-child {
			margin-bottom: 0;

			&::before {
				border-width: 4px;
				width: $timeline-message-point-size-first;
				height: $timeline-message-point-size-first;
				inset-block-end: $timeline-message-point-position;
				inset-block-start: unset;
				transform: translateX(-50%) translateY(50%);

				@at-root [dir='rtl'] & {
					transform: translateX(50%) translateY(50%);
				}
			}

			.timeline-message__inner::after {
				inset-block-start: unset;
				inset-block-end: $timeline-message-point-position;
				transform: translateX(-100%) translateY(50%);

				@at-root [dir='rtl'] & {
					transform: translateX(100%) translateY(50%);
				}
			}
		}
	}
	&--closed-by-user {
		.timeline-message:last-child {
			&::before {
				transform: translateX(50%) translateY(50%);

				@at-root [dir='rtl'] & {
					transform: translateX(-50%) translateY(50%);
				}
			}
		}
	}
}