$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Form control
 -  (<input class="form-control" type="text" />)
 */


.form-group--text-input {
	// needs to be set explicitly, control is wrapped in another div
	&.has-error .invalid-feedback {
		display: block;
	}

	.form-control.is-invalid + .btn {
		color: $danger;
	}
}
