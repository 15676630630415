$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Timeline Message
 */


.timeline-message {
	position: relative;
	width: 100%;
	display: flex;
	margin-block-end: ( $spacer * .5 );
	justify-content: flex-end;

	// Media queries
	@include media-breakpoint-up(lg) {
		width: 80%;
	}

	&::before {
		content: '';
		position: absolute;
		inset-block-start: $timeline-message-point-position;
		inset-inline-start: 0;
		background-color: $timeline-message-point-bg-color;
		border: 3px solid $timeline-message-point-border-color;
		border-radius: 100%;
		width: $timeline-message-point-size;
		height: $timeline-message-point-size;
		box-shadow: $timeline-message-point-shadow;
		transform: translate(-50%, -50%);

		@at-root [dir='rtl'] & {
			transform: translate(50%, -50%);
		}
	}

	&:first-child {
		&::before {
			border-width: 4px;
			width: $timeline-message-point-size-first;
			height: $timeline-message-point-size-first;
		}
	}

	&__icon {
		position: absolute;
		inset-block-start: ( $spacer + 1rem );
		inset-inline-start: 0;
		transform: translate(-50%, -50%);
		z-index: 1;

		@at-root [dir='rtl'] & {
			transform: translate(50%, -50%);
		}
	}

	&__title {
		[class^='icon'],
		[class*='icon'] {
			position: relative;
			inset-block-end: 1px;
			vertical-align: middle;
		}
	}

	&__timestamp {
		margin-block-end: ( $spacer * .25 );
	}

	&__text {
		margin-block-start: ( $spacer * .5 );
		line-height: 22px;
		max-height: 67px;
		height: auto;
		overflow: hidden;

		&--more {
			max-height: none;
		}
	}

	&__read {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		[class^='icon'],
		[class*='icon'] {
			padding-right: ( $spacer * .5 );

		}
	}
	&--confirmed {
		&::before {
			border: none;
			border-color: transparent;
			box-shadow: $box-shadow-success;
		}

		&:not(:first-child) {
			.timeline-message__icon {
				// stylelint-disable-next-line
				font-size: 1.3rem !important;
			}
		}
	}

	&--internal {
		// Media queries
		@include media-breakpoint-up(lg) {
			justify-content: flex-start;
			float: inline-start;
			clear: inline-start;

			&::before {
				inset-inline-start: auto;
				inset-inline-end: 0;
				transform: translate(50%, -50%);

				@at-root [dir='rtl'] & {
					transform: translate(-50%, -50%);
				}
			}

			& + & + .timeline-message--external {
				margin-block-start: ( $spacer * 2 );
			}
		}

		.timeline-message__inner {
			border-inline-end: $timeline-message-border solid $timeline-message-internal-color;

			// Media queries
			@include media-breakpoint-up(lg) {
				border-inline-end: none;
				border-inline-start: $timeline-message-border solid $timeline-message-internal-color;

				&::after {
					inset-inline-start: auto;
					inset-inline-end: -30px;
					border-width: $timeline-message-border 0 $timeline-message-border $timeline-message-border!important;
					border-style: solid;
					border-color:  transparent transparent transparent $timeline-message-bg-color!important;
				}
			}
		}

		&.timeline-message--system {
			.timeline-message__inner {
				border-inline-start-color: $timeline-message-system-color;
				border-inline-end: none;

				&::after {
					border-color: transparent transparent transparent $timeline-message-system-bg-color;
				}
			}
		}
	}

	&--external {
		// Media queries
		@include media-breakpoint-up(lg) {
			float: inline-end;
			clear: inline-end;

			& + & + .timeline-message--internal {
				margin-block-start: ( $spacer * 2 );
			}
		}

		.timeline-message__inner {
			border-inline-end: $timeline-message-border solid $timeline-message-external-color;
		}
	}

	&--system {
		.timeline-message__inner {
			background-color: $timeline-message-system-bg-color;
			border-inline-end-color: $timeline-message-system-color;

			&::after {
				border-color: transparent $timeline-message-system-bg-color transparent transparent;
			}
		}
	}
}

.timeline-message__inner {
	position: relative;
	background-color: $timeline-message-bg-color;
	padding: $spacer;
	border-radius: $border-radius;
	width: calc(100% - #{$spacer * 1.5});

	// Media queries
	@include media-breakpoint-up(lg) {
		width: calc(100% - #{$spacer * 2});
	}

	&::after {
		content: '';
		position: absolute;
		width: 15px;
		height: 20px;
		inset-block-start: $timeline-message-point-position;
		inset-inline-start: 0;
		border-style: solid;
		border-inline-start-width: 0;
		border-inline-end-width: $timeline-message-border;
		border-block-end-width: $timeline-message-border;
		border-block-start-width: $timeline-message-border;
		border-inline-start-color: transparent;
		border-inline-end-color: $timeline-message-bg-color;
		border-block-end-color: transparent;
		border-block-start-color: transparent;
		transform: translate(-100%, -50%);

		@at-root [dir='rtl'] & {
			transform: translate(100%, -50%);
		}
	}
}