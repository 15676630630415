$env-myci-instance: omn;
@import '../../../ui-components/src/web/scss/core';

.file-input {
	&__dropzone {
		@include file-input-dashed-border(5%);
		color: $theme-gray-500;
		display: flex;
		flex-direction: column;
		min-height: $file-input-height;

		&-inner {
			align-items: center;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: center;
			min-height: inherit;
			padding: logical $file-input-spacing;
			text-align: center;

			&:hover {
				cursor: pointer;
			}
		}

		&-inner--uploaded-file {
			align-items: flex-start;
			color: $theme-black;
			text-align: left;

			&:hover {
				cursor: unset;
			}

			@include media-breakpoint-up(md) {
				align-items: center;
				flex-direction: row;
				justify-content: space-between;
			}
		}
	}

	&__placeholder {
		@include file-input-dashed-border(5%);
		color: $theme-gray-500;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 3rem;
		opacity: .5;
		min-height: $file-input-placeholder-height;

		&:hover {
			cursor: not-allowed;
		}
	}

	&__browse-link {
		font-weight: 700;
	}

	&__browse-icon {
		// stylelint-disable-next-line declaration-no-important
		color: $theme-gray-400 !important; // sadly, assigning color to icons is stupidly designed
	}

	&__file {
		background-repeat: no-repeat;
		background-size: contain;
		padding-inline-start: $file-input-file-name-offset;

		&:dir(rtl) {
			background-position-x: right;
		}

		@include media-breakpoint-up(md) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:focus {
			outline: 0;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&__file--pdf {
		@include file-input-icon($file-input-pdf, $file-input-pdf-active);
	}

	&__file--image {
		@include file-input-icon($file-input-image, $file-input-image-active);
	}

	&__actions {
		align-self: flex-start;
		display: flex;
		padding-inline-start: $file-input-icon-width;

		@include media-breakpoint-up(md) {
			align-self: unset;
			padding-inline-start: unset;
		}
	}
}

.file-input__dropzone-inner--disabled {
	cursor: not-allowed;

	&:hover {
		cursor: not-allowed;
	}

	.file-input__browse-link {
		color: #999;

		&:hover {
			cursor: not-allowed;
			text-decoration: none;
		}
	}
}
