$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Form control
 -  (<input class="form-control" type="*" />)
 */


.form-control {
	&:not(:disabled):not(:read-only):hover {
		border-color: $input-hover-border-color;
	}

	&[disabled],
	&[readonly],
	&[disabled] + .control-label,
	&[readonly] + .control-label {
		color: $control-label-disabled-color;
	}

	&.is-invalid,
	&:invalid {
		color: $input-invalid-color;
		background: $input-bg;
		padding-right: $input-padding-x;
	}

	&.form-control--editable {
		padding: logical 0;
		height: auto;
	}

	&.empty-checkbox + .control-label {
		text-indent: -9999px;
		width: 0;
	}


	&__legend {
		font-size: 1.2rem;
		color: $info;
		padding-inline-start: calc(#{$input-padding-x} + #{$input-border-width});
		margin-block-start: $spacer * .25;
		margin-block-end: 0;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}
}

