$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Backdrop
 -  (<div class="backdrop" />)
 */

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: $zindex-modal-backdrop;
	background-color: rgba($theme-gray-800, .3);
	opacity: 0;
	transition: opacity ( $transition-speed * 2 ) $transition-easing;

	// Media queries
	@include media-breakpoint-up(sm) {
		padding: ( $spacer * 2 );
	}

	&.show {
		display: flex;
		opacity: 1;

		.modal {
			transform: translateY(0);
		}
	}
}