/*
 -	Viewport Helper
 */


.debug-viewports {
	position: fixed;
	bottom: 0;
	left: 0;

	&__viewport {
		@include legacy-font-size(14);
		padding: 4px 8px;
		background-color: $primary;
		font-weight: 600;
		font-size: 1.2rem;
		text-transform: uppercase;
		color: $theme-white;
	}
}
