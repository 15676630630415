$icons: (
	administration:               '\e900',
	status-processing:            '\e901',
	data-management:              '\e902',
	reports:                      '\e903',
	creditinfo-reports:           '\e903',
	menu:                         '\e904',
	cancel:                       '\e905',
	caret-down:                   '\e906',
	caret-up:                     '\e907',
	search:                       '\e908',
	clear:                        '\e909',
	kebab:                        '\e90a',
	maintenance:                  '\e90b',
	user:                         '\e90c',
	statistics:                   '\e90d',
	success-circle:               '\e90e',
	add-circle:                   '\e90f',
	change-history:               '\e910',
	language:                     '\e911',
	help:                         '\e912',
	response:                     '\e913',
	audit:                        '\e914',
	billing:                      '\e915',
	calendar:                     '\e916',
	rollback:                     '\e917',
	restart:                      '\e918',
	success:                      '\e919',
	disputes:                     '\e91a',
	next:                         '\e91b',
	download:                     '\e91c',
	cancel-upload:                '\e91d',
	upload:                       '\e91e',
	plus:                         '\e91f',
	rejected:                     '\e920',
	error:                        '\e921',
	status-not-approved:          '\e922',
	status-rejected:              '\e923',
	status-rollback:              '\e924',
	status-success:               '\e925',
	status-uploading:             '\e926',
	status-waiting:               '\e927',
	monitoring:                   '\e928',
	chevron-right:                '\e929',
	chevron-left:                 '\e92a',
	upload-file:                  '\e92b',
	file:                         '\e92c',
	skip-prev-page:               '\e92d',
	skip-next-page:               '\e92e',
	prev-page:                    '\e92f',
	next-page:                    '\e930',
	no-data:                      '\e931',
	subscriber:                   '\e932',
	person:                       '\e933',
	back:                         '\e934',
	empty-set:                    '\e935',
	status-info:                  '\e936',
	pencil:                       '\e937',
	group:                        '\e938',
	show:                         '\e939',
	bell:                         '\e93a',
	alerts:                       '\e93a',
	add-filter:                   '\e93b',
	show-password:                '\e93c',
	strong-password:              '\e93d',
	weak-password:                '\e93e',
	hide-password:                '\e93f',
	dashboard:                    '\e940',
	contact:                      '\e941',
	encumbrances:                 '\e942',
	inquiries:                    '\e943',
	collaterals:                  '\e944',
	contracts:                    '\e945',
	incidents:                    '\e946',
	relations:                    '\e947',
	status-na:                    '\e948',
	status-not-specified:         '\e949',
	status-incomplete:            '\e94a',
	send:                         '\e94b',
	info:                         '\e94c',
	remove-circle:                '\e94d',
	id:                           '\e94e',
	details:                      '\e94f',
	reason-codes:                 '\e950',
	delete:                       '\e951',
	generation-ocb-id:            '\e952',
	export:                       '\e953',
	subcontract:                  '\e954',
	calendar-due:                 '\e955',
	close-circle:                 '\e956',
	finance:                      '\e957',
	cheques:                      '\e958',
	supervisory-reports:          '\e959',
	external:                     '\e95a',
	internal:                     '\e95b',
	system:                       '\e95c',
	attachment:                   '\e95d',
	save:                         '\e95e',
	snapshot:                     '\e95f',
	trend-forward:                '\e960',
	trend-trendup:             	  '\e961',
	trend-trenddown:              '\e962',
	trend-trendnochange:          '\e963',
	chevron-down:                 '\e964',
	mobile:                       '\e965',
	contact-v2:                   '\e966',
	login:                        '\e967',
	map-pin:                      '\e968',
	products:                     '\e969',
	home:                         '\e96a',
	subscriptions:                '\e96b',
	settings:                     '\e96c',
	wallet:                       '\e96d',
	pdf:                          '\e96e',
	folder:                       '\e96f',
	image:                        '\e970',
	share:                        '\ea82'
);