$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Footer
 */


.footer {
	width: 100%;
	padding: logical ( $spacer * .75 ) $grid-gutter-width;
	background-color: $theme-bg;
	box-shadow: $footer-box-shadow;

	// Media queries
	@include media-breakpoint-up(sm) {
		padding: logical ( $spacer * .75 ) ( $main-nav-width-tablet + $grid-gutter-width ) ( $spacer * .75 ) $grid-gutter-width;
	}
	@include media-breakpoint-up(lg) {
		position: fixed;
		inset-block-end: 0;
		inset-inline-end: 0;
		max-height: $footer-height;
		padding-inline-start: ( $main-nav-width + ( $grid-gutter-width ));
		// Navigations has zindex-fixed
		z-index: $zindex-fixed - 1;
	}

	&__text {
		text-align: end;
	}
}
