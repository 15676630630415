$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Navigation
 -  (<nav class="main-nav" />)
 */


.main-nav {
	position: fixed;
	width: 100%;
	inset-block-start: 0;
	inset-inline-start: 0;
	padding: logical $spacer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $main-nav-height-mobile;
	z-index: $zindex-fixed;
	background-color: $main-nav-bg;
	transition: all ( $transition-speed * 2 ) $transition-easing;

	// Media queries
	@include media-breakpoint-up(sm) {
		width: $main-nav-width-tablet;
		height: $main-nav-height;
		flex-direction: column;
		padding: logical 0;
	}
	@include media-breakpoint-up(lg) {
		width: $main-nav-width;
	}

	&::after {
		content: '';
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		width: 100%;
		height: 100%;
		box-shadow: $box-shadow-basic;
		z-index: $zindex-fixed;
		pointer-events: none;
	}

	&--open {
		.sub-nav-mobile {
			position: relative;
			box-shadow: inset 0 10px 20px -10px rgba($theme-black, .09);

			&::after {
				content: '';
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: 0;
				width: 100%;
				height: 100%;
				box-shadow: inset 0 -10px 20px -10px rgba($theme-black, .09);
				z-index: $zindex-fixed;
				pointer-events: none;
			}
		}

		.sub-nav {
			transform: translateX(0);
		}

		.main-nav__holder {
			display: flex;
		}

		.main-nav__mobile-toggle {
			span {
				&:first-child,
				&:last-child {
					opacity: 0;
					transform: translateX(100%);
				}

				&:nth-child(2) {
					transform: rotate(-45deg);

					&::after {
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	&--wide {
		width: $main-nav-width;

		.sub-nav {
			inset-inline-start: $main-nav-width;
		}

		.main-nav__link-text {
			flex: 1;
		}

		.main-nav__tablet-switch {
			transform: rotate(180deg);
		}
	}
}

.main-nav__holder {
	position: fixed;
	inset-block-start: 100%;
	inset-inline-start: 0;
	top: #{$main-nav-height-mobile};
	width: 100%;
	height: calc(100% - #{$main-nav-height-mobile});
	display: none;
	flex-direction: column;
	z-index: $zindex-fixed;
	background-color: $main-nav-bg;

	// Media queries
	@include media-breakpoint-up(sm) {
		position: relative;
		inset-block-start: 0;
		height: calc(100% - #{$main-nav-logo-height-tablet});
		padding: logical ( $spacer * 2 ) 0 0;
		display: flex;
	}
	@include media-breakpoint-up(lg) {
		height: calc(100% - #{$main-nav-logo-height});
		padding: logical 0;
		width: $main-nav-width;
	}
}

.main-nav__inner {
	position: relative;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.main-nav__list,
.sub-nav__list,
.sub-nav-mobile__list {
	list-style-type: none;
	padding: logical 0;
	margin: logical 0;
	width: 100%;
	overflow-y: auto;
}

.main-nav__logo {
	height: $main-nav-logo-height-mobile;
	width: auto;
	overflow: hidden;

	// Media queries
	@include media-breakpoint-up(sm) {
		width: 100%;
		height: $main-nav-logo-height-tablet;
		padding: logical $spacer 0;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		border-bottom: 1px solid $theme-gray-300;
	}
	@include media-breakpoint-up(lg) {
		width: $main-nav-logo-width;
		height: $main-nav-logo-height;
		justify-content: center;
		border-bottom: none;
		margin: 1 * $spacer;
	}

	&-holder {
		width: 80%;
		height: 100%;
	}

	img {
		width: auto;
		height: 100%;

		// Media queries
		@include media-breakpoint-up(sm) {
			display: block;
			max-width: 80%;
			max-height: ( $main-nav-logo-height-tablet - 40px );
			height: auto;
			margin-block-end: 0;
		}

		@include media-breakpoint-up(lg) {
			max-width: 100%;
			max-height: $main-nav-logo-height;
		}
	}
}

.main-nav__tablet-switch {
	display: none;

	// Media queries
	@include media-breakpoint-up(sm) {
		position: relative;
		display: block;
		width: $main-nav-width-tablet;
		height: 30px;
		// !important for overriding icon sizes
		// stylelint-disable-next-line
		font-size: 2.8rem !important;
		flex-shrink: 0;
		text-align: center;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.sub-nav {
	display: none;

	// Media queries
	@include media-breakpoint-up(sm) {
		position: fixed;
		inset-block-start: 0;
		inset-inline-start: $main-nav-width-tablet;
		width: $sub-nav-width;
		height: 100vh;
		display: flex;
		flex-direction: column;
		background-color: $sub-nav-bg;
		box-shadow: 15px 0 15px -15px rgba($theme-black, .09);
		transform: translateX(-#{$sub-nav-width});
		transition: all ( $transition-speed * 2 ) $transition-easing;

		&[dir='rtl'] {
			transform: translateX(#{$sub-nav-width});
		}
	}
	@include media-breakpoint-up(lg) {
		inset-inline-start: $main-nav-width;
	}

	&__list {
		margin-block-start: calc(#{$main-nav-logo-height-tablet} + #{$spacer * 2});

		// Media queries
		@include media-breakpoint-up(lg) {
			margin-block-start: calc(#{$main-nav-logo-height});
		}
	}

	&-mobile {
		overflow: hidden;
		display: flex;
		height: 0;
		transition: all ( $transition-speed * 2 ) $transition-easing;

		.sub-nav__link {
			padding-inline-start: ( $spacer + $main-nav-link-active-border-width + $main-nav-link-icon-size + $spacer );
		}

		// Media queries
		@include media-breakpoint-up(sm) {
			display: none;
		}
	}
}

[dir='rtl'] {
	.sub-nav {
		// Media queries
		@include media-breakpoint-up(sm) {
			transform: translateX(#{$sub-nav-width});
		}
	}

	.main-nav--open .sub-nav {
		transform: translateX(0);
	}

	.main-nav {
		.main-nav__tablet-switch {
			transform: rotate(180deg);
		}

		&--wide {
			.main-nav__tablet-switch {
				transform: rotate(0);
			}
		}
	}

	.main-nav__link,
	.sub-nav__link {
		&::before {
			transform: translateX(100%);
		}

		&.active {
			&::before {
				transform: translateX(0);
			}
		}
	}
}

.main-nav__link {
	position: relative;
	overflow: hidden;

	&--download-report {
		margin-block-start: auto;
	}

	&--bordered {
		border-block-start: 1px solid $theme-gray-300;
	}

	&::before {
		content: '';
		position: absolute;
		inset-inline-start: 0;
		inset-block-start: 0;
		height: 100%;
		width: $main-nav-link-active-border-width;
		background-color: $main-nav-link-active-bg;
		transform: translateX(-100%);
		transition: transform $transition-speed $transition-easing;
	}

	[class^='icon'],
	[class*='icon'] {
		// !important for overriding icon sizes
		// stylelint-disable-next-line
		font-size: $main-nav-link-icon-size !important;
		margin-inline-end: $spacer;
		transition: color $transition-speed $transition-easing;
	}

	&.active {
		font-weight: 700;

		&::before {
			transform: translateX(0);
		}

		[class^='icon'],
		[class*='icon'] {
			// stylelint-disable-next-line
			color: $main-nav-link-active-bg !important;
		}
	}

	&.open {
		& + .sub-nav-mobile {
			flex: 1;
			height: auto;
		}
	}
}

.sub-nav__link {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		inset: 0 0 auto;
		top: 0;
		height: 100%;
		width: $main-nav-link-active-border-width;
		background-color: $main-nav-link-active-bg;
		transform: translateX(-100%);
		transition: transform $transition-speed $transition-easing;
	}

	[class^='icon'],
	[class*='icon'] {
		font-size: $main-nav-link-icon-size;
		margin-right: $spacer;
		transition: color $transition-speed $transition-easing;
	}

	&.active {
		font-weight: 700;

		&::before {
			transform: translateX(0);
		}

		[class^='icon'],
		[class*='icon'] {
			color: $main-nav-link-active-bg;
		}
	}
}

.main-nav__link,
.sub-nav__link {
	width: 100%;
	height: $main-nav-link-height;
	padding-inline: ( $spacer + $main-nav-link-active-border-width ) $spacer;
	display: flex;
	align-items: center;
	text-align: start;
	color: $body-color;
	font-size: $main-nav-link-font-size;
	line-height: ( 20 / 15 );
	transition: background-color $transition-speed $transition-easing;
	cursor: pointer;

	br {
		display: none;

		// Media queries
		@include media-breakpoint-up(sm) {
			display: inline;
		}
	}

	&:hover,
	&:focus {
		background-color: $main-nav-link-hover-bg;
		text-decoration: none;
		color: $body-color;
	}
}

.main-nav__link-text {
	// Media queries
	@include media-breakpoint-up(sm) {
		flex: 0;
		transition: flex ( $transition-speed * 2 ) $transition-easing;
	}
	@include media-breakpoint-up(lg) {
		flex: auto;
	}
}

.main-nav__mobile-toggle {
	width: 24px;
	height: 22px;
	display: inline-flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-end;
	margin-inline-start: auto;
	overflow: hidden;

	// Media queries
	@include media-breakpoint-up(sm) {
		display: none;
	}

	span {
		display: block;
		height: 4px;
		border-radius: 4px;
		background-color: $theme-gray-800;
		opacity: 1;
		transition: all $transition-speed $transition-easing;

		&:first-child,
		&:last-child {
			width: 80%;
		}

		&:nth-child(2) {
			position: relative;
			width: 100%;

			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 4px;
				background-color: $theme-gray-800;
				transition: all $transition-speed $transition-easing;
			}
		}
	}
}

.main-nav__user {
	// Media queries
	@include media-breakpoint-down(xs) {
		padding-inline-end: 10.5rem;

		.main-nav__link-text {
			@include text-truncate;
		}
	}
}

.main-nav__sign-out {
	// Media queries
	@include media-breakpoint-down(xs) {
		position: absolute;
		inset-inline-end: 0;
		inset-block-start: ( $main-nav-link-height * .5 );
		transform: translateY(-50%);
		z-index: $zindex-fixed + 1;
	}
}

.main-nav__scroll {
	height: 50px;
	width: 100%;
	text-align: center;
}
