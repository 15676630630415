@import '../../scss/core';

/*
 -  Slide Modal
 -  (<div class="slide-modal" />)
 */


.slide-modal {
	position: fixed;
	inset-inline-end: 0;
	inset-block-start: 0;
	height: 100vh;
	width: 100%;
	max-width: $slide-modal-width;
	background-color: $slide-modal-bg;
	box-shadow: $box-shadow-basic;
	overflow-y: auto;
	z-index: $zindex-modal;

	// Media queries
	@include media-breakpoint-up(md) {
		width: 70%;
	}

	&--sm {
		// Media queries
		@include media-breakpoint-up(md) {
			width: $slide-modal-width-sm;

			.slide-modal__footer {
				width: $slide-modal-width-sm;
			}
		}
	}

	&--lg {
		// Media queries
		@include media-breakpoint-up(md) {
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			width: auto;
			max-width: none;
			inset-inline-start: $main-nav-width;

			.slide-modal__footer {
				width: auto;
				max-width: none;
				inset-inline-start: $main-nav-width;
			}
		}
	}

	&__controls {
		position: absolute;
		inset-block-start: 0;
		inset-inline: 0;
		height: $slide-modal-controls-mobile-height;
		padding: logical $slide-modal-mobile-padding-y $slide-modal-mobile-padding-x 0;

		// Media queries
		@include media-breakpoint-up(md) {
			height: $slide-modal-controls-height;
			padding: logical $slide-modal-padding-y $slide-modal-padding-x 0;
		}
	}

	&__header {
		margin-block: ( $spacer * .5 ) ( $spacer * 1.5 );
		padding: logical $slide-modal-controls-mobile-height $slide-modal-mobile-padding-x 0;

		// Media queries
		@include media-breakpoint-up(md) {
			padding: logical $slide-modal-controls-height $slide-modal-padding-x 0 ;
		}
	}

	&__title {
		width: 100%;
		font-weight: 800;
		font-size: 2.6rem;
		margin-block-end: ( $spacer * .5 );
		line-height: 1.2;

		// Media queries
		@include media-breakpoint-up(sm) {
			width: auto;
			margin-block-end: 0;
			font-size: 3.6rem;
		}
	}

	&__sub-title {
		font-size: 1.8rem;
		margin-block: ( $spacer * .5 ) 0;
		@include text-truncate;

		// Media queries
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	&__content {
		padding-inline: $slide-modal-mobile-padding-x;
		padding-block-end: calc(#{$slide-modal-footer-mobile-height} + #{$spacer});

		// Media queries
		@include media-breakpoint-up(md) {
			padding-inline: $slide-modal-padding-x;
		}
		@include media-breakpoint-up(xl) {
			padding-block-end: calc(#{$slide-modal-footer-height} + #{$spacer * 2});
		}

		.content-divider {
			margin-inline: -$grid-gutter-width;
		}

		.content-inner {
			padding-inline: 0;
		}
	}

	&__footer {
		width: 100%;
		margin-top: auto;
		height: $slide-modal-footer-mobile-height;
		padding: logical $slide-modal-mobile-padding-y $slide-modal-mobile-padding-x ( $slide-modal-mobile-padding-y * 2 );
		background-color: $slide-modal-bg;
		box-shadow: 0 -20px 40px 0 rgba($theme-black, .04);

		// Media queries
		@include media-breakpoint-up(md) {
			height: $slide-modal-footer-height;
			padding: logical $slide-modal-padding-y $slide-modal-padding-x;
		}
		@include media-breakpoint-up(xl) {
			position: fixed;
			inset-block-end: 0;
			inset-inline-end: 0;
			max-width: $slide-modal-width;
		}
	}
}

.slide-modal-transition {
	&-enter {
		.slide-modal {
			transform: translateX(100%);
		}

		&.backdrop {
			opacity: 0;
		}

		&-active {
			.slide-modal {
				transform: translateX(0%);
				transition: transform 1000ms ease-in-out;
			}

			&.backdrop {
				opacity: 1;
			}
		}
	}

	&-exit {
		.slide-modal {
			transform: translateX(0%);
		}

		&.backdrop {
			opacity: 1;
		}

		&-active {
			.slide-modal {
				transform: translateX(100%);
				transition: transform 1000ms ease-in-out;
			}

			&.backdrop {
				opacity: 0;
			}
		}
	}
}