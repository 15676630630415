$env-myci-instance: omn;
@import '../../scss/core';

/*
 -  Switch
 */

$switch-button-position: $toggle-switch-width - $toggle-switch-icon-size - $toggle-switch-icon-offset * 2;

.switch {
	display: inline-flex;
	position: relative;
	height: $toggle-switch-height;

	.form-group--radio {
		padding-inline-start: 0;
		overflow: hidden;

		.form-control ~ .control-label::before {
			content: none;
		}

		.form-control ~ .control-label {
			text-transform: uppercase;
			height: $toggle-switch-height;
			line-height: $toggle-switch-height;
		}

		.form-control:checked ~ .control-label {
			font-weight: 800;
		}

		.form-control ~ .control-label::after {
			content: attr(data-label);
			position: relative;
			width: auto;
			height: auto;
			inset-inline-start: 0;
			line-height: 1;
			visibility: hidden;
			pointer-events: none;
			background-color: transparent;
			border: none;
			font-weight: 800;
		}

		&:first-child .form-control ~ .control-label {
			padding-inline-end: ( $spacer * .5 );

			// Media queries
			@include media-breakpoint-up(sm) {
				padding-inline-end: $spacer;
			}
		}

		&:last-child .form-control ~ .control-label {
			padding-inline-start: ( $spacer * .5 );

			// Media queries
			@include media-breakpoint-up(sm) {
				padding-inline-start: $spacer;
			}
		}
	}

	.form-group + .form-group {
		margin-block-start: 0;
	}

	&__button {
		position: relative;
		height: $toggle-switch-height;
		width: $toggle-switch-width;
		margin-block-end: 0;
		background-color: $toggle-switch-bg;
		border-radius: ( $toggle-switch-height * .5 );
		display: inline-flex;
		align-items: center;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			inset-block-start: $toggle-switch-icon-offset;
			inset-inline: auto $toggle-switch-icon-offset;
			display: block;
			width: $toggle-switch-icon-size;
			height: $toggle-switch-icon-size;
			background-color: $toggle-switch-icon-color;
			border-radius: $toggle-switch-icon-size;
			font-size: $toggle-switch-icon-size;
			transition: all $transition-speed $transition-easing;
			transform: translateX(0);
			box-shadow: 0 2px 10px 0 rgba($theme-black, .3);
		}

		&--checked {
			&::before {
				transform: translateX(-$switch-button-position);
			}
		}
	}
}

[dir='rtl'] {
	.switch__button--checked::before {
		transform: translateX($switch-button-position);
	}
}