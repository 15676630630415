$env-myci-instance: omn;
@import '../../scss/core';

/*
 -	Form tooltip
 -	(<Tooltip class="tooltip" />)

	TODO Kuba Misenko wanted to refactor
 */

$bgColor: rgb(33, 37, 41);

.tooltip {
	background-color: $bgColor;
	opacity: .9;
	font-size: 12px;
	color: rgb(255, 255, 255);
	border-radius: 4px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, .2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1.3rem .4rem 1rem;
	padding: .4rem 1rem;
	transition: opacity .3s;
	// Popover needs to be fixed to work with touch events
	z-index: $zindex-tooltip;

	&.upper-case {
		text-transform: uppercase;
	}
}

.tooltip-arrow {
	height: 1rem;
	position: absolute;
	width: 1rem;
	color: $bgColor;
}

.tooltip-arrow::before {
	border-style: solid;
	content: '';
	display: block;
	height: 0;
	margin: auto;
	width: 0;
}

.tooltip-arrow::after {
	border-style: solid;
	content: '';
	display: block;
	height: 0;
	margin: auto;
	position: absolute;
	width: 0;
}

.tooltip-arrow[data-placement*='bottom'] {
	height: 1rem;
	left: 0;
	margin-top: -.4rem;
	top: 0;
	width: 1rem;
}

.tooltip-arrow[data-placement*='bottom']::before {
	border-color: transparent transparent $bgColor transparent;
	border-width: 0 .5rem .4rem;
	position: absolute;
	top: -1px;
}

.tooltip-arrow[data-placement*='bottom']::after {
	border-color: transparent transparent $bgColor transparent;
	border-width: 0 .5rem .4rem;
}

.tooltip-arrow[data-placement*='top'] {
	bottom: 0;
	height: 1rem;
	left: 0;
	margin-bottom: -1rem;
	width: 1rem;
}

.tooltip-arrow[data-placement*='top']::before {
	border-color: $bgColor transparent transparent transparent;
	border-width: .4rem .5rem 0;
	position: absolute;
	top: 1px;
}

.tooltip-arrow[data-placement*='top']::after {
	border-color: $bgColor transparent transparent transparent;
	border-width: .4rem .5rem 0;
}

.tooltip-arrow[data-placement*='right'] {
	height: 1rem;
	left: 0;
	margin-left: -.7rem;
	width: 1rem;
}

.tooltip-arrow[data-placement*='right']::before {
	border-color: transparent $bgColor transparent transparent;
	border-width: .5rem .4rem .5rem 0;
}

.tooltip-arrow[data-placement*='right']::after {
	border-color: transparent $bgColor transparent transparent;
	border-width: .5rem .4rem .5rem 0;
	left: 6px;
	top: 0;
}

.tooltip-arrow[data-placement*='left'] {
	height: 1rem;
	margin-right: -.7rem;
	right: 0;
	width: 1rem;
}

.tooltip-arrow[data-placement*='left']::before {
	border-color: transparent transparent transparent $bgColor;
	border-width: .5rem 0 .5rem .4em;
}

.tooltip-arrow[data-placement*='left']::after {
	border-color: transparent transparent transparent $bgColor;
	border-width: .5rem 0 .5rem .4em;
	left: 4px;
	top: 0;
}
