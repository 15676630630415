$env-myci-instance: omn;
@import '../../../../libs/ui-components/src/web/scss/core';

.dispute-attachment {
	align-items: center;
	display: flex;

	&__name {
		text-decoration: underline;

		&:hover {
			color: $primary;
			cursor: pointer;
			text-decoration: none;
		}
	}
}